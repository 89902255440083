import React, { useState } from 'react'
import { Box, Button, Flex } from '@chakra-ui/react'
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5'
import { getTodayTiming, filterTime } from './utils'

const StoreTiming = ({ storeTiming, index }) => {
  const [show, setShow] = useState(false)
  const onClickShow = () => {
    setShow(!show)
  }
  const style = {
    marginLeft: '10px',
    marginTop: '0.25px',
  }

  return (
    <>
      <Box fontSize={14}>
        <Box cursor={'pointer'}>
          <Button
            color="black"
            variant="link"
            _hover={{ textDecoration: 'none' }}
            onClick={onClickShow}
            marginBottom={!show ? '0px' : '12px'}
          >
            <span dangerouslySetInnerHTML={{ __html: getTodayTiming(storeTiming) }}></span>{' '}
            <span style={style}>
              {!show ? <IoChevronDownOutline fontSize={16} /> : <IoChevronUpOutline fontSize={16} />}
            </span>{' '}
          </Button>
        </Box>
        <Box>{show && <div dangerouslySetInnerHTML={{ __html: filterTime(storeTiming) }}></div>}</Box>
      </Box>
    </>
  )
}

export default StoreTiming
