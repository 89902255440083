import React, { useEffect, useMemo } from 'react'
import { Box, Heading } from '@chakra-ui/react'
import atgAxiosInstance from 'helpers/axios-atg-helper'
import { useFormat } from 'helpers/hooks/useFormat'
import Toast from 'react-hot-toast'
import { useAtgUser, useStore } from 'frontastic/contexts'
import { StoreListItem } from './store-list-item'
import StoreNotFound from './store-not-found'
import { storesDistance, savedStoreToAtgSession } from './utils'
import { useLocalStorage } from '../hooks/useLocalStorage'

export const StoreList = ({
  addressLocation,
  showNoResults,
  isOpen,
  onClose,
  storeHeading,
  isStoreSearch,
  setIsStoreSearch,
}) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const {
    selectedStoreData,
    nearByStoreListDataForFindStores,
    isPDPPage,
    setSelectedStoreData,
    setSelectedStoreListData,
    setNearByStoreListDataForFindStores,
    isInStorePickUp,
    setIsInStorePickUp,
  } = useStore()
  const { fetchUserSessionData } = useAtgUser()

  const [userSearchNewZipCodeLatLng, setUserSearchNewZipCodeLatLng] = useLocalStorage('userSearchNewZipCodeLatLng', {
    lat: '',
    lng: '',
  })

  const handleSelectionOfNewStore = (store: any) => {
    setSelectedStoreData({ ...store, addressLocation })
    // set the new selected store to ATG
    savedStoreToAtgSession(
      {
        storeId: store.storeId,
      },
      fetchUserSessionData,
    )

    // select just 5 first stores
    setSelectedStoreListData(nearByStoreListDataForFindStores.slice(0, 5))

    setTimeout(() => {
      Toast.success(`Store ${store?.address?.city} set succesfull`)
    }, 300)

    /*
      Setting this to [] because a new store is now selected and
      we do not want to display a list of near by stores for the previous zipcode in the store drawer
    */
    setNearByStoreListDataForFindStores([])
    setIsInStorePickUp(false)
    setIsStoreSearch(false)
    onClose({ ...store, addressLocation })
  }

  const listOfStores = useMemo(() => {
    return (
      <>
        <Heading
          style={storeHeading}
          height={'32px'}
          display={'flex'}
          alignItems={'stretch'}
          justifyContent={'flex-end'}
          flexDirection={'column'}
          mb={'8px'}
          color={'text.primary'}
          tabIndex={0}
        >
          {formatMessage({ id: 'mystore.nearby.store' })}
        </Heading>

        {Array.isArray(nearByStoreListDataForFindStores) &&
          nearByStoreListDataForFindStores?.map((store) => {
            const storeDistance = storesDistance(
              userSearchNewZipCodeLatLng.lat,
              userSearchNewZipCodeLatLng.lng,
              store?.geoLocation?.coordinates[1],
              store?.geoLocation?.coordinates[0],
            ).toFixed(0)

            return store?.id !== selectedStoreData?.id ? (
              <StoreListItem
                key={store.id} // Use store.id as the unique key
                storeData={store}
                distance={+storeDistance}
                storeHeading={storeHeading}
                selectedStoreOnly={false}
                handleSelectionOfNewStore={handleSelectionOfNewStore}
              />
            ) : null
          })}
      </>
    )
  }, [nearByStoreListDataForFindStores, userSearchNewZipCodeLatLng, isPDPPage, isInStorePickUp])

  useEffect(() => {
    if (isOpen) {
      setNearByStoreListDataForFindStores([])
      setIsStoreSearch(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <>
      {selectedStoreData && selectedStoreData?.address?.city !== '' && (
        <Box>
          <Heading
            style={storeHeading}
            height={'32px'}
            display={'flex'}
            alignItems={'stretch'}
            justifyContent={'flex-end'}
            flexDirection={'column'}
            color={'text.primary'}
            tabIndex={0}
          >
            {formatMessage({ id: 'mystore.title' })}
          </Heading>

          {/* selected store information */}
          <StoreListItem
            storeData={selectedStoreData}
            distance={
              selectedStoreData?.geoLocation?.coordinates[1]
                ? +storesDistance(
                    userSearchNewZipCodeLatLng.lat,
                    userSearchNewZipCodeLatLng.lng,
                    selectedStoreData?.geoLocation?.coordinates[1],
                    selectedStoreData?.geoLocation?.coordinates[0],
                  ).toFixed(0)
                : 0
            }
            storeHeading={storeHeading}
            selectedStoreOnly={true}
            handleSelectionOfNewStore={handleSelectionOfNewStore}
          />
        </Box>
      )}

      {nearByStoreListDataForFindStores?.length > 0
        ? listOfStores
        : (isStoreSearch || showNoResults) && <StoreNotFound />}
    </>
  )
}
