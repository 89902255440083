import { FormEvent, useState } from 'react'
import { Box, Button, SelectField, SimpleGrid, FormControl, FormLabel, Input } from '@chakra-ui/react'
import axios from 'axios'
import { useFormat } from 'helpers/hooks/useFormat'
import { usePdp } from 'frontastic'
import { useStore } from 'frontastic/contexts'
import { distances, GEO_MAP_URL } from './constants'
import { useLocalStorage } from '../hooks/useLocalStorage'

export const StoreSearch = ({ setDataOnClickOfFindStores, shippingType }) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const { getStore } = usePdp()
  const { currentPDPVariant, isPDPPage, setNearByStoreListDataForFindStores } = useStore()

  const [userSearchNewZipCodeLatLng, setUserSearchNewZipCodeLatLng] = useLocalStorage('userSearchNewZipCodeLatLng', {
    lat: '',
    lng: '',
  })

  const [city, setCity] = useState('')
  const [distance, setDistance] = useState('25')

  const isError = city === ''

  const getLatLng = async (city: string) => {
    const res = await axios.get(`${GEO_MAP_URL}&address=${city}`)
    const { data } = res

    if (data && data?.status == 'OK') {
      const newZipCodeLatLng = data.results[0].geometry.location
      setUserSearchNewZipCodeLatLng(newZipCodeLatLng)
      await findStoresNearByTheNewZipCode(distance, newZipCodeLatLng)
    } else if (data.status == 'ZERO_RESULTS') {
      setDataOnClickOfFindStores({ storeData: {}, addressLocation: null, showNoResults: true, isStoreSearch: true })
    } else {
      setDataOnClickOfFindStores({ storeData: {}, addressLocation: null, showNoResults: true, isStoreSearch: true })
    }
  }

  const findStoresNearByTheNewZipCode = async (distance, newZipCodeLatLng) => {
    if (newZipCodeLatLng?.lat !== '') {
      const getStorePayload = {
        ...shippingType,
        edp: isPDPPage ? currentPDPVariant.sku : '',
        lat: newZipCodeLatLng.lat,
        lng: newZipCodeLatLng.lng,
        radius: distance,
        isPDPPage: isPDPPage,
      }
      try {
        const res = await getStore(getStorePayload)
        const resObj = { storeData: res }
        setDataOnClickOfFindStores({
          ...resObj,
          addressLocation: newZipCodeLatLng,
          showNoResults: false,
          isStoreSearch: true,
        })
        setNearByStoreListDataForFindStores(res)
        return res
      } catch (error) {
        console.error('Error in fetching stores by zip code', error)
      }
    }
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>): Promise<void> {
    event.preventDefault()
    await getLatLng(city)
  }

  return (
    <form onSubmit={handleSubmit}>
      <SimpleGrid columns={{ md: 2, sm: 1, xs: 1 }} spacing={2}>
        <Box>
          <FormControl>
            <FormLabel>{formatMessage({ id: 'text.mystore' })}</FormLabel>
            <Input
              className="border bg-transparent font-normal"
              borderColor={'#CACFD7'}
              style={{ borderRadius: '6px', width: '100%' }}
              required
              placeholder="Location"
              type="text"
              onChange={(e) => setCity(e.target.value)}
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl isInvalid={isError}>
            <FormLabel>{formatMessage({ id: 'mystore.distance.in.miles' })}</FormLabel>
            <SelectField
              w="100%"
              borderColor={'#CACFD7'}
              required
              onChange={(e) => setDistance(e.target.value)}
              style={{ borderRadius: '6px', fontWeight: 'normal', paddingBottom: '0.36rem' }}
            >
              {distances.map((value, index) => (
                <option key={index} value={value.value}>
                  {value.viewValue}
                </option>
              ))}
            </SelectField>
          </FormControl>
        </Box>
      </SimpleGrid>
      <Button type="submit" bgColor="#000" my={'16px'} width="100%">
        {formatMessage({ id: 'mystore.find.stores' })}
      </Button>
    </form>
  )
}
